<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img :src="appLogoImage" alt="logo" width="40" height="32" />
      <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Accès Réfusé, vous n'etes pas autorisé! 🔐</h2>
        <p class="mb-2">
          Vous n'avez pas de permission pour accéder a cette page. Retourner à
          l'accueil!!
        </p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="{ name: 'dashboard-default' }"
          >Retourner à l'accueil</b-button
        >
        <b-img fluid :src="imgUrl" alt="Accès Réfusé" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from "bootstrap-vue";
import AppLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    AppLogo,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, api_endpoint } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
      api_endpoint,
    };
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem("userData"));
      return getHomeRouteForLoggedInUser(user ? user.role : null);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
